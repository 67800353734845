import { KTIcon } from "../../_theme/helpers";

export function Sidebar({ showDrawer, onHandleSidebar }: any) {
    return (
        <>
            <div id="kt_app_sidebar">
                <div className={`app-sidebar flex-column d-lg-none drawer drawer-start ${showDrawer && 'drawer-on'} w-225px`}>
                    <div className="app-sidebar-menu overflow-hidden flex-column-fluid">
                        <div className="app-sidebar-wrapper hover-scroll-overlay-y my-5">
                            <div className="menu menu-column menu-rounded menu-sub-indention px-3">
                                <div className="menu-item">
                                    <a className={`menu-link without-sub ${document.location.pathname == '/how-to-use' ? 'active' : ''}`} href="/how-to-use">
                                        {/* <span className="menu-icon">
                                            <KTIcon iconName="element-11" className="fs-2" />
                                        </span> */}
                                        <span className="menu-title">How to use ?</span>
                                    </a>
                                </div>
                                <div className="menu-item">
                                    <a className={`menu-link without-sub ${document.location.pathname == '/about-us' ? 'active' : ''}`} href="/about-us">
                                        {/* <span className="menu-icon">
                                            <KTIcon iconName="element-11" className="fs-2" />
                                        </span> */}
                                        <span className="menu-title">About us</span>
                                    </a>
                                </div>
                                <div className="menu-item">
                                    <a className={`menu-link without-sub ${document.location.pathname == '/help-and-support' ? 'active' : ''}`} href="/help-and-support">
                                        {/* <span className="menu-icon">
                                            <KTIcon iconName="element-11" className="fs-2" />
                                        </span> */}
                                        <span className="menu-title">Help & Support</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    showDrawer &&
                    <div className="drawer-overlay" style={{zIndex: 105}} onClick={onHandleSidebar}></div>
                }
            </div>
        </>
    )
}