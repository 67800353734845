import { useEffect } from "react"
import { Outlet } from "react-router-dom"

import "../../../_theme/assets/css/auth.css"
import { toAbsoluteUrl } from "../../../_theme/helpers"

const AuthLayout = () => {
    useEffect(() => {
        const root = document.getElementById("root")
        if (root) {
            root.style.height = "100%"
        }
        return () => {
            if (root) {
                root.style.height = "auto"
            }
        }
    }, [])

    return (
        <div className="auth-container">
            <div className="auth-inner">
                <div className="auth-form">
                    <div className="app-logo text-center mb-4">
                        <img src={toAbsoluteUrl("/media/logos/default.png")} alt="ZupShare" />
                    </div>
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export { AuthLayout }