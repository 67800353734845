import { useState } from "react";
import { KTIcon, toAbsoluteUrl } from "../../_theme/helpers";
import { Sidebar } from "./Sidebar";
import { useAuth } from "../modules/auth";
import { HeaderUserMenu } from "./HeaderUserMeny";

export function Header() {
    const [showDrawer, setShowDrawer] = useState(false)
    const { currentUser } = useAuth()
    
    const onHandleSidebar = () => {
        setShowDrawer(!showDrawer);
    }
    
    window.addEventListener('resize', function() {
        setShowDrawer(false);
    });
    
    return (
        <>
            <div className="app-header">
                <div className="app-sidebar-logo px-6">
                    <a href="/">
                        <img alt="Logo" src={toAbsoluteUrl('/media/logos/default.png')} className="h-50px" />
                    </a>
                </div>
                <div className="app-container container-fluid d-flex align-items-stretch justify-content-between">
                    <div className="d-flex align-items-center d-lg-none ms-n2 me-2">
                        <div className="btn btn-icon btn-active-color-primary w-35px h-35px" onClick={onHandleSidebar}>
                            <KTIcon iconName="abstract-14" className="fs-1" />
                        </div>
                        <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                            <a className="d-lg-none" href="/">
                                <img alt="Logo" src={toAbsoluteUrl('/media/logos/default.png')} className="h-30px" />
                            </a>
                        </div>
                    </div>
                    <div className="d-flex align-items-stretch justify-content-between flex-lg-grow-1">
                        <div className="app-header-menu app-header-mobile-drawer align-items-stretch">
                            <div className="menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0">
                                <div className="menu-item me-lg-1">
                                    <a href="/how-to-use" className={`menu-link py-3 menu-here ${document.location.pathname === '/how-to-use' ? 'active' : ''}`}>
                                        <span className="menu-title">How to use?</span>
                                    </a>
                                </div>
                                <div className="menu-item me-lg-1">
                                    <a href="/about-us" className={`menu-link py-3 menu-here ${document.location.pathname === '/about-us' ? 'active' : ''}`}>
                                        <span className="menu-title">About us</span>
                                    </a>
                                </div>
                                <div className="menu-item me-lg-1">
                                    <a href="/help-and-support" className={`menu-link py-3 menu-here ${document.location.pathname === '/help-and-support' ? 'active' : ''}`}>
                                        <span className="menu-title">Help & Support</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        {!currentUser &&
                            <div className="app-navbar flex-shrink-0">
                                <div className="app-navbar-item my-2 me-2">
                                    <a href="/auth/login" className="btn btn-sm btn-secondary fw-bold fs-6">Login</a>
                                </div>
                                <div className="app-navbar-item my-2">
                                    <a href="/auth/signup" className="btn btn-sm btn-secondary fw-bold fs-6">Sign Up</a>
                                </div>
                            </div>
                        }
                        {currentUser &&
                            <div className="app-navbar flex-shrink-0">
                                <div className="app-navbar-item my-2">
                                    <div className="cursor-pointer symbol symbol-35px"
                                        data-kt-menu-trigger="{default: 'click'}"
                                        data-kt-menu-attach='parent'
                                        data-kt-menu-placement='bottom-end'
                                    >
                                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="avatar" />
                                    </div>
                                    <HeaderUserMenu />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <Sidebar showDrawer={showDrawer} onHandleSidebar={onHandleSidebar} />
        </>
    )
}