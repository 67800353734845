import { Header } from "../components"

export function HowToUse() {
    return (
        <>
            <Header />
            <div className="app-conatiner w-lg-50 px-6 mx-auto pt-10">
                <h1 className="text-center mb-6">How To Use</h1>
                <ul>
                    <li>For sending scan QR code or enter eight digit UID (xxxxx123)</li>
                    <li>For receving you need to have account</li>
                    <li>For saving data of your chats also need a complsary account</li>
                </ul>
            </div>
        </>
    )
}