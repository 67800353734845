import { ILayout } from "./_Models"

export const DefaultConfig: ILayout = {
    main: {
        componentName: "main",
        type: "default",
        pageBgWhite: false,
        iconType: "duotone"
    },
    app: {
        general: {
            componentName: 'general',
            evolution: true,
            layoutType: 'default',
            mode: 'light',
            rtl: false,
            primaryColor: '#50CD89',
            pageBgWhite: false,
            pageWidth: 'default',
        },
        content: {
            componentName: "content",
            container: "fluid"
        },
        pageLoader: {
            componentName: "page-loader",
            type: "none",
            logoImage: "default.png",
            logoClass: "mh-75px"
        }
    }
}