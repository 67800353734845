

import { Navigate, Route, Routes } from "react-router-dom";
//import { WithChildren } from "../../_theme/helpers";
import { MasterLayout } from "../../_theme/layout/MasterLayout";
import { Dashboard } from "../pages/dashboard";

const PrivateRoutes = () => {
    
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />

                {/* Pages */}
                <Route path='dashboard' element={<Dashboard />} />


                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

export { PrivateRoutes }