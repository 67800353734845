import { FC } from "react"
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom"
import { AuthPage, Logout, useAuth } from "../modules/auth"
import { App } from "../App"
import { PrivateRoutes } from "./PrivateRoutes"
import { AuthByScan } from "../modules/auth/components/AuthByScan"
import { Home } from "../pages/Home"
import { HowToUse } from "../pages/HowToUse"
import { AboutUs } from "../pages/AboutUs"
import { HelpAndSupport } from "../pages/HelpAndSupport"

const {PUBLIC_URL} = process.env || "/"

const AppRoutes: FC = () => {
    const { currentUser } = useAuth()
    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>
                    <Route path="logout" element={<Logout />} />
                    <Route path="verifying/:token" element={<AuthByScan />} />
                    <Route path="/" element={<Home />} />
                    {currentUser ? (
                        <>
                            <Route path='/*' element={<PrivateRoutes />} />
                            {/*<Route index element={<Navigate to='/dashboard' />} />*/}
                        </>
                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthPage />} />
                            <Route path="*" element={<Navigate to="/" />} />
                        </>
                    )}
                    <Route path="how-to-use" element={<HowToUse />} />
                    <Route path="about-us" element={<AboutUs />} />
                    <Route path="help-and-support" element={<HelpAndSupport />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export { AppRoutes }