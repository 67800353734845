import { ChatBox } from "./components/ChatBox"
import { Sidebar } from "./components/Sidebar"
import { QueryRequestProvider } from "./core/QueryRequestProvider"
import { QueryResponseProvider } from "./core/QueryResponseProvider"
import { MessageProvider } from "./core/MessageContext"

const ChatMain = () => {
    return (
        <QueryRequestProvider>
            <QueryResponseProvider>
                <MessageProvider>
                    <div className='d-flex flex-column flex-lg-row h-100'>
                        <Sidebar />
                        <ChatBox />
                    </div>
                </MessageProvider>
            </QueryResponseProvider>
        </QueryRequestProvider>
    )
}

export { ChatMain }