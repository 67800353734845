import { useMessageContext } from "../core/MessageContext"
import { KTIcon } from "../../../../_theme/helpers"
import { useAuth } from "../../auth"

const Search = () => {
    const { logout } = useAuth()
    const { searchTerm, setSearchTerm } = useMessageContext()

    return (
        <div className='card-header pt-7 ps-3 ps-lg-9 gap-4' id='kt_chat_contacts_header'>
            <div className="d-flex align-items-center flex-grow-1 gap-4">
                <div className="d-flex d-lg-none">
                    <a href="/" className="d-flex align-items-center justify-content-center">
                        <KTIcon iconName="arrow-left" className="fs-3x" />
                    </a>
                </div>
                <form className='w-100 position-relative' autoComplete='off'>
                    <KTIcon
                        iconName='magnifier'
                        className='fs-2 text-lg-1 text-gray-500 position-absolute top-50 ms-5 translate-middle-y'
                    />

                    <input
                        type='text'
                        className='form-control form-control-solid px-15'
                        name='search'
                        placeholder='Search by name or uid...'
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </form>
            </div>
            <div className="d-flex align-items-center cursor-pointer"
                data-kt-menu-trigger="click"
                data-kt-menu-attach='parent'
                data-kt-menu-placement='bottom-end'
            >
                <KTIcon iconName="dots-vertical" className="fs-4x fs-lg-2x" />
            </div>
            <div 
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-150px"
                data-kt-menu="true"
            >
                <div className='menu-item px-5 d-none d-lg-block'>
                    <a href="/" className='menu-link px-5'>
                        Home
                    </a>
                </div>
                <div className='menu-item px-5'>
                    <a onClick={logout} className='menu-link px-5'>
                        Log Out
                    </a>
                </div>
            </div>
        </div>
    )
}

export { Search }