import { Route, Routes } from "react-router-dom"
import { AuthLayout } from "./AuthLayout"
import { Login } from "./components/Login"
import { Registration } from "./components/Registration"
import { ForgotPassword } from "./components/ForgotPassword"
import { ResetPassword } from "./components/ResetPassword"

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Registration />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="reset-password" element={<ResetPassword />} />
            <Route index element={<Login />} />
        </Route>
    </Routes>
)

export { AuthPage }