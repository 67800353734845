import clsx from "clsx"
import { useFormik } from "formik"
import { useState } from "react"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { resetPassword } from "../core/_requests"

const initialValues = {
    password: '',
    retype_password: '',
    token: ''
}

const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password is required'),
    retype_password: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Password confirmation is required')
        .oneOf([Yup.ref('password')], "Password and Confirm Password didn't match")
})

export function ResetPassword() {
    const [loading, setLoading] = useState(false)
    const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)

    const formik = useFormik({
        initialValues,
        validationSchema: resetPasswordSchema,
        onSubmit:  (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            setHasErrors(undefined)
            setTimeout(function() {
                resetPassword(values.password, values.retype_password, values.token)
                    .then(({data: {result}}) => {
                        setHasErrors(false)
                        setLoading(false)
                    })
                    .catch(() => {
                        setHasErrors(true)
                        setLoading(false)
                        setSubmitting(false)
                        setStatus('Incorrect detail')
                    })
            }, 1000)
        }
    })

    return (
        <form
            className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
            noValidate
            id="password_reset_form"
            onSubmit={formik.handleSubmit}
        >  
            {/* begin::Title */}
            <div className='text-center mb-10'>
                {/* begin::Title */}
                <h1 className='text-dark fw-bolder mb-3'>Set new Password</h1>
                {/* end::Title */}

                {/* begin::Link */}
                <div className='text-gray-500 fw-semibold fs-6'>
                Your new password must be different to previously used passwords.
                </div>
                {/* end::Link */}
            </div>

            {hasErrors === true && (
                <div className='mb-lg-15 alert alert-danger'>
                <div className='alert-text font-weight-bold'>
                    Sorry, looks like there are some errors detected, please try again.
                </div>
                </div>
            )}

            {hasErrors === false && (
                <div className='mb-10 bg-light-info p-8 rounded'>
                <div className='text-info'>Your password has been changed.</div>
                </div>
            )}

            {/* begin::Form group Password */}
            <div className='fv-row mb-4'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <div className='position-relative mb-3'>
                    <input
                        type='password'
                        placeholder='Password'
                        autoComplete='off'
                        {...formik.getFieldProps('password')}
                        className={clsx(
                            'form-control bg-transparent',
                            {
                            'is-invalid': formik.touched.password && formik.errors.password,
                            },
                            {
                            'is-valid': formik.touched.password && !formik.errors.password,
                            }
                        )}
                    />
                    {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                        </div>
                    </div>
                    )}
                </div>
            </div>
            {/* end::Form group */}
            
            {/* begin::Form group Confirm password */}
            <div className='fv-row mb-8'>
                <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
                <input
                    type='password'
                    placeholder='Password confirmation'
                    autoComplete='off'
                    {...formik.getFieldProps('retype_password')}
                    className={clsx(
                        'form-control bg-transparent',
                        {
                        'is-invalid': formik.touched.retype_password && formik.errors.retype_password,
                        },
                        {
                        'is-valid': formik.touched.retype_password && !formik.errors.retype_password,
                        }
                    )}
                />
                {formik.touched.retype_password && formik.errors.retype_password && (
                    <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.retype_password}</span>
                        </div>
                    </div>
                )}
            </div>
            {/* end::Form group */}
            
            {/* begin::Form group */}
            <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
                <button type='submit' id='password_reset_submit' className='btn btn-primary me-4'>
                <span className='indicator-label'>Submit</span>
                {loading && (
                    <span className='indicator-progress'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                )}
                </button>
                <Link to='/auth/login'>
                    <button
                        type='button'
                        id='password_reset_form_cancel_button'
                        className='btn btn-light'
                        disabled={formik.isSubmitting || !formik.isValid}
                    >
                        Cancel
                    </button>
                </Link>{' '}
            </div>
            {/* end::Form group */}
        </form>
    )
}