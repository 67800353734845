import { Header } from "../components";

export function HelpAndSupport() {
    return (
        <>
            <Header />
            <div className="app-conatiner w-lg-50 px-6 mx-auto pt-10">
                <h1 className="text-center mb-6">Help & Support</h1>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora tempore nemo eum, quidem a, voluptate quaerat laborum distinctio praesentium exercitationem repellendus sit fugiat, officiis inventore! Iure accusamus quis facere ipsum cupiditate, nulla natus earum accusantium, commodi beatae, nihil repellat aliquam ad esse tempore assumenda necessitatibus obcaecati eius nobis vitae. Quo, pariatur, consectetur optio alias cumque porro explicabo quos commodi dolore aliquid mollitia quis beatae! Obcaecati, assumenda vitae cupiditate enim ullam reiciendis at quis temporibus rem suscipit sunt tempore perspiciatis omnis maxime error voluptatum pariatur praesentium voluptatibus aliquam quam! Cum inventore, dignissimos itaque at iusto perferendis obcaecati aperiam sit adipisci voluptates!
            </div>
        </>
    )
}