import { Outlet } from "react-router-dom"
import { AuthInit } from "./modules/auth"
import { Suspense } from "react"
import { LayoutProvider, LayoutSplashScreen } from "../_theme/layout/core"
import { MasterInit } from "../_theme/layout/MasterInit"

const App = () => {
    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <LayoutProvider>
                <AuthInit>
                    <Outlet />
                    <MasterInit />
                </AuthInit>
            </LayoutProvider>
        </Suspense>
    )
}

export { App }