import { createRoot } from 'react-dom/client'
// Axios
import axios from 'axios';
import { QueryClient, QueryClientProvider } from 'react-query'
// Apps
import { AppRoutes } from './app/routing/AppRoutes'
import { AuthProvider, setupAxios } from './app/modules/auth'

import './_theme/assets/keenicons/duotone/style.css'
import './_theme/assets/keenicons/outline/style.css'
import './_theme/assets/keenicons/solid/style.css'

import "./_theme/assets/sass/style.scss"
import "./_theme/assets/sass/plugins.scss"
import "./_theme/assets/sass/style.react.scss"
import "./_theme/assets/css/custom.css"

setupAxios(axios)

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </QueryClientProvider>
  )
}