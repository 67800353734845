import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { reInitMenu } from "../helpers"


const MasterLayout = () => {
    const location = useLocation()
    useEffect(() => {
        reInitMenu()
    }, [location.key])

    return (
        <>
            <Outlet />
        </>
    )
}

export { MasterLayout }