import { createContext, useContext, useEffect, useState } from "react";
import { useQueryResponseData, useQueryResponseLoading } from "./QueryResponseProvider";
import { useQueryRequest } from "./QueryRequestProvider";
import { initialQueryState, useDebounce } from "../../../../_theme/helpers";
import socket from "../../../../socket";
import { useAuth } from "../../auth";
import { useLocation } from "react-router-dom";

const MessageContext = createContext<any>({});

export const MessageProvider = ({ children }:any) => {
    const location = useLocation()
    const activeConversationId = location?.state?.activeConversationId

    const {currentUser} = useAuth()
    const [activeConversation, setActiveConversation]:any = useState(null)
    const [conversations, setConversations]:any = useState([])
    const [messages, setMessages]:any = useState([]);
    const [message, setMessage] = useState('');
    const [typingUsers, setTypingUsers] = useState({});
    const [files, setFiles] = useState<File[]>([]);
    const [hasMore, setHasMore] = useState(false);
    const [cursor, setCursor] = useState(0);
    const isLoading = useQueryResponseLoading()
    
    const data = useQueryResponseData()
    
    const {updateState} = useQueryRequest()
    const [searchTerm, setSearchTerm] = useState<string>('')
    // Debounce search term so that it only gives us latest value ...
    // ... if searchTerm has not been updated within last 500ms.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(searchTerm, 150)
    // Effect for API call
    useEffect(
        () => {
          if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
            updateState({search: debouncedSearchTerm, ...initialQueryState})
          }
        },
        [debouncedSearchTerm] // Only call effect if debounced search term changes
        // More details about useDebounce: https://usehooks.com/useDebounce/
    )

    useEffect(() => { 
        if (data.length > 0 || searchTerm != "") {
            setConversations(data)
        }
    }, [data, setConversations, searchTerm])
    
    useEffect(() => {
        if (activeConversationId) {
            setActiveConversation(conversations?.find((conversation: any) => conversation?.id == activeConversationId));
        }
    }, [activeConversationId, conversations]);

    useEffect(() => {

        // Listen for incoming messages
        socket.on("messageResponse", (messages) => {
            const messagesLength = messages.length;
            const latestMessage = messages.at(-1)

            if (latestMessage?.conversation_id == activeConversation?.id) {
                setMessages((prevMessages: any) => [
                    ...prevMessages,
                    ...messages
                ])
            }

            setConversations((prevConversations: any) => {
                const latestConversations = prevConversations.map((conversation: any) =>
                        (conversation?.id === activeConversation?.id && currentUser?.id === latestMessage?.user_id)
                            ? { ...conversation, latest_message: latestMessage, unread_count: 0 }
                            : conversation?.id === latestMessage?.conversation_id ? { ...conversation, latest_message: latestMessage, unread_count: (messagesLength + conversation?.unread_count) } : conversation
                        )
                    return latestConversations.sort((a: any, b: any) => new Date(b?.latest_message?.created_at).getTime() - new Date(a?.latest_message?.created_at).getTime());
                }
            )            

            if (latestMessage?.conversation_id == activeConversation?.id && currentUser?.id != latestMessage?.user_id) {
                socket.emit("messageRead", {conversationId: latestMessage?.conversation_id});
            }

            //setIsInitialLoad(true); // Set Scroll Position to New Message
            
        })

        // Listen for "typing" event
        socket.on("typingResponse", ({conversationId, userId, isTyping}) => {
            setTypingUsers((prev) => ({ ...prev, ['typing_response']: {['conversation_id']: conversationId, ['user_id']: userId, ['is_typing']: isTyping} }));
        })

        // Listen for incoming messages
        socket.on("messageReadResponse", (responseConversation) => {
            setConversations((prevConversations: any) =>
                prevConversations.map((conversation: any) =>
                  (conversation?.id == activeConversation?.id && responseConversation.id == activeConversation?.id)
                    ? { ...conversation, latest_message: responseConversation?.latest_message, unread_count: 0 }
                    : conversation
                )
            )
        })

        // Cleanup listener on unmount
        return () => {
            socket.off("messageResponse");
            socket.off("typingResponse");
            socket.off("messageReadResponse");
        };
    }, [activeConversation, setConversations])

    return (
        <MessageContext.Provider
            value={{ activeConversation, setActiveConversation, conversations, setConversations, messages, setMessages, message, setMessage, files, setFiles, typingUsers, setTypingUsers, cursor, setCursor, hasMore, setHasMore, searchTerm, setSearchTerm, isLoading }}
        >
            {children}
        </MessageContext.Provider>
    )
}

export const useMessageContext = () => useContext(MessageContext)