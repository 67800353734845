import {useEffect, useRef} from 'react'
import {
    ScrollComponent,
    MenuComponent
} from '../assets/ts/components'

import {useLayout} from './core'

export function MasterInit() {
    const {config} = useLayout()
    const isFirstRun = useRef(true)
    const pluginsInitialization = () => {
      isFirstRun.current = false
      setTimeout(() => {
        ScrollComponent.bootstrap()
        MenuComponent.bootstrap()
      }, 500)
    }
  
    useEffect(() => {
      if (isFirstRun.current) {
        isFirstRun.current = false
        pluginsInitialization()
      }
    }, [config])
  
    return <></>
}