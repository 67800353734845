import { Header } from "../components";

export function AboutUs() {
    return (
        <>
            <Header />
            <div className="app-conatiner w-lg-50 px-6 mx-auto pt-10">
                <h1 className="text-center mb-6">About ZupShare</h1>
                <ul>
                    <li>zupshare is sharing platform that can help to share messages, photos, vidieos and others.</li>
                    <li>Our goal is to provide a safe and supportive platform where individuals can share particluar fastly and smoother</li>
                </ul>
            </div>
        </>
    )
}