import { io } from "socket.io-client";
//const URL = `http://localhost:4200`;
const URL = `https://zupshare.com:4200`;

const socket = io(URL, { autoConnect: false });

socket.onAny((event, ...args) => {
    console.log(event, args);
});

export default socket;