import { 
    createContext,
    FC, 
    useContext, 
    useEffect,
    useState
} from "react"
import { WithChildren } from "../../helpers"
import { ILayout, ILayoutCSSClasses, ILayoutCSSVariables } from "./_Models"
import { getEmptyCssClasses, getEmptyCSSVariables, LayoutSetup } from "./_LayoutSetup"
import { DefaultConfig } from "./_LayoutConfig"

export interface LayoutContextModel {
    config: ILayout
    classes: ILayoutCSSClasses
    cssVariables: ILayoutCSSVariables
    setLayout: (config: LayoutSetup) => void
}

const LayoutContext = createContext<LayoutContextModel>({
    config: DefaultConfig,
    classes: getEmptyCssClasses(),
    cssVariables: getEmptyCSSVariables(),
    setLayout: (config: LayoutSetup) => {},
})

const enableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
        splashScreen.style.setProperty('display', 'flex')
    }
}
  
const disableSplashScreen = () => {
    const splashScreen = document.getElementById('splash-screen')
    if (splashScreen) {
        splashScreen.style.setProperty('display', 'none')
    }
}

const LayoutProvider: FC<WithChildren> = ({children}) => {
    const [config, setConfig] = useState(LayoutSetup.config)
    const [classes, setClasses] = useState(LayoutSetup.classes)
    const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)

    const setLayout = (_themeConfig: Partial<ILayout>) => {
        enableSplashScreen()
        const bodyClasses = Array.from(document.body.classList)
        bodyClasses.forEach((cl) => document.body.classList.remove(cl))
        const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig)
        setConfig(Object.assign({}, updatedConfig))
        setClasses(LayoutSetup.classes)
        setCSSVariables(LayoutSetup.cssVariables)
        setTimeout(() => {
          disableSplashScreen()
        }, 500)
    }

    const value: LayoutContextModel = {
        config,
        classes,
        cssVariables,
        setLayout,
    }

    useEffect(() => {
        disableSplashScreen()
    }, [])

    return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export { LayoutContext, LayoutProvider }

export function useLayout() {
    return useContext(LayoutContext)
}
