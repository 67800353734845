import React, { FC } from "react"
import { ChatMain } from "../../modules/chat";

const Dashboard: FC = () => {
    document.body.classList.add('overflow-hidden');
    
    return (
        <>
            <div id="app-container" className="app-container zup-container container-fluid py-6 py-md-10">
                <ChatMain />
            </div>
        </>
    )
}

export { Dashboard }