import { createContext, useEffect, useState } from "react";
import { QueryResponseContextProps, QueryState } from "./models";
import qs from "qs";

function createResponseContext<T>(initialState: QueryResponseContextProps<T>) {
    return createContext(initialState)
}

function isNotEmpty(obj: unknown) {
    return obj !== undefined && obj !== null && obj !== ''
}

// Example: page=1&items_per_page=10&sort=id&order=desc&search=a&filter_name=a&filter_online=false
function stringifyRequestQuery(state: QueryState): string {
    const pagination = qs.stringify(state, {filter: ['page'], skipNulls: true})
    const search = isNotEmpty(state.search)
        ? qs.stringify(state, {filter: ['search'], skipNulls: true})
        : ''

    return [pagination, search]
        .filter((f) => f)
        .join('&')
        .toLowerCase()
}

function parseRequestQuery(query: string): QueryState {
    const cache: unknown = qs.parse(query)
    return cache as QueryState
}

// Hook
function useDebounce(value: string | undefined, delay: number) {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState(value)
    useEffect(
      () => {
        // Update debounced value after delay
        const handler = setTimeout(() => {
          setDebouncedValue(value)
        }, delay)
        // Cancel the timeout if value changes (also on delay change or unmount)
        // This is how we prevent debounced value from updating if value is changed ...
        // .. within the delay period. Timeout gets cleared and restarted.
        return () => {
          clearTimeout(handler)
        }
      },
      [value, delay] // Only re-call effect if value or delay changes
    )
    return debouncedValue
}

async function getIP(type = 'ipv6') {
    let url = `https://api64.ipify.org?format=json`;
    if (type == 'ipv4') {
        url = `https://api.ipify.org?format=json`;
    }
    const response = await fetch(url);
    const data = await response.json();
    return data.ip;
}

export {
    createResponseContext,
    stringifyRequestQuery,
    parseRequestQuery,
    isNotEmpty,
    useDebounce,
    getIP
}