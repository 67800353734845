import axios, { AxiosResponse } from "axios"

import { GET_CONVERSATION_MESSAGES_URL, GET_CONVERSATIONS_URL, CONVERSATION_MARK_AS_READ_URL, GET_USER_HISTORY } from "../../../api"

const getUsers = (query?: string): Promise<any> => {
    return axios
      .get(`${GET_CONVERSATIONS_URL}${query ? `?${query}` : ``}`)
      .then(response => response.data)
      .then((response: AxiosResponse<any>) => response.data)
}

const getUserMessages = (conversationId: any, query?: string): Promise<any> => {
  return axios
    .get(`${GET_CONVERSATION_MESSAGES_URL(conversationId)}${query ? `?${query}` : ``}`)
    .then(response => response.data)
    .then((response: AxiosResponse<any>) => response.data)
}

const markConversationAsRead = (conversationId: any): Promise<any> => {
  return axios
    .get(CONVERSATION_MARK_AS_READ_URL(conversationId))
    .then(response => response.data)
    .then((response: AxiosResponse<any>) => response.data)
}

const getUserHistory = (): Promise<any> => {
  return axios
    .get(GET_USER_HISTORY)
    .then(response => response.data)
    .then((response: AxiosResponse<any>) => response.data)
}

export { getUsers, getUserMessages, markConversationAsRead, getUserHistory };