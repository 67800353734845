import { DefaultConfig } from "./_LayoutConfig"
import { ILayout, ILayoutCSSClasses, ILayoutCSSVariables } from "./_Models"

const LAYOUT_CONFIG_KEY = process.env.REACT_APP_BASE_LAYOUT_CONFIG_KEY || 'LayoutConfig'

const getLayoutFromLocalStorage = (): ILayout => {
    const ls = localStorage.getItem(LAYOUT_CONFIG_KEY)
    if (ls) {
        try {
            return JSON.parse(ls) as ILayout
        } catch (er) {
            console.error(er)
        }
    }
    return DefaultConfig
}

const setLayoutIntoLocalStorage = (config: ILayout) => {
    try {
        localStorage.setItem(LAYOUT_CONFIG_KEY, JSON.stringify(config))
    } catch (er) {
        console.error(er)
    }
}

const getEmptyCssClasses = (): ILayoutCSSClasses => {
    return {
        content: [],
        contentContainer: [],
    }
}

const getEmptyCSSVariables = () => {
    return {
      body: new Map(),
    }
}

class LayoutSetup {
    public static isLoaded: boolean = false
    public static config: ILayout = getLayoutFromLocalStorage()
    public static classes: ILayoutCSSClasses = getEmptyCssClasses()
    public static cssVariables: ILayoutCSSVariables = getEmptyCSSVariables()

    private static initCSSClasses(): void {
        LayoutSetup.classes = getEmptyCssClasses()
    }

    private static initCSSVariables(): void {
        LayoutSetup.cssVariables = getEmptyCSSVariables()
    }

    private static initConfig(config: ILayout): ILayout {
        let updatedConfig = LayoutSetup.initLayoutSettings(config)
        return LayoutSetup.initWidthSettings(updatedConfig)
    }

    private static initLayoutSettings(config: ILayout): ILayout {
        const updatedConfig = {...config}
        // clear body classes
        document.body.className = ''
        // clear body attributes
        const bodyAttributes = document.body.getAttributeNames().filter((t) => t.indexOf('data-') > -1)
        bodyAttributes.forEach((attr) => document.body.removeAttribute(attr))
        document.body.setAttribute('style', '')
        document.body.setAttribute('id', 'app_body')
        //document.body.setAttribute('data-app-layout', updatedConfig.layoutType)
        document.body.setAttribute('data-kt-app-layout', 'dark-sidebar')
        document.body.classList.add('app-default')

        //const pageWidth = updatedConfig.app?.general?.pageWidth

        return updatedConfig
    }

    private static initWidthSettings(config: ILayout): ILayout {
        const updatedConfig = {...config}
        const pageWidth = updatedConfig.app?.general?.pageWidth
        if (!pageWidth || pageWidth === 'default') {
            return config
        }

        const content = updatedConfig.app?.content
        if (content) {
            content.container = pageWidth
        }

        const updatedApp = {
            ...updatedConfig.app,
            ...content,
        }
        return {...updatedConfig, app: updatedApp}
    }

    public static updatePartialConfig(fieldsToUpdate: Partial<ILayout>): ILayout {
        const config = LayoutSetup.config
        const updatedConfig = {...config, ...fieldsToUpdate}
        LayoutSetup.initCSSClasses()
        LayoutSetup.initCSSVariables()
        //LayoutSetup.initHTMLAttributes()
        LayoutSetup.isLoaded = false
        LayoutSetup.config = LayoutSetup.initConfig(Object.assign({}, updatedConfig))
        LayoutSetup.isLoaded = true // remove loading there
        return updatedConfig
    }

    public static setConfig(config: ILayout): void {
        setLayoutIntoLocalStorage(config)
    }

    public static bootstrap = (() => {
        LayoutSetup.updatePartialConfig(LayoutSetup.config)
    })()
}

export {
    LayoutSetup,
    getLayoutFromLocalStorage,
    setLayoutIntoLocalStorage,
    getEmptyCssClasses,
    getEmptyCSSVariables
}
