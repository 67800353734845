export type PaginationState = {
    page: number
    links?: Array<{label: string; active: boolean; url: string | null; page: number | null}>
}

export type SearchState = {
    search?: String
}

export type Response<T> = {
    data?: T
    payload?: {
      message?: string
      errors?: {
        [key: string]: Array<string>
      }
      pagination?: PaginationState
    }
}

export type QueryState = PaginationState & SearchState

export type QueryRequestContextProps = {
    state: QueryState
    updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
    page: 1
}

export const initialQueryRequest: QueryRequestContextProps = {
    state: initialQueryState,
    updateState: () => {},
}

export type QueryResponseContextProps<T> = {
    response?: Response<Array<T>> | undefined
    refetch: () => void
    isLoading: boolean
    query: string
}
  
export const initialQueryResponse = {refetch: () => {}, isLoading: false, query: ''}