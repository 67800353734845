import { Link } from "react-router-dom";
import { useAuth } from "../modules/auth";

export function HeaderUserMenu() {
    const { currentUser, logout } = useAuth()

    return (
        <div 
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
            data-kt-menu="true"
        >
            <div className='menu-item px-5'>
                <a onClick={logout} className='menu-link px-5'>
                Log Out
                </a>
            </div>
        </div>
    )
}