import { toAbsoluteUrl } from "../../../../_theme/helpers";
import { useAuth } from "../../auth";
import { useMessageContext } from "../core/MessageContext";
import { Search } from "./Search";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

const Sidebar = () => {
	const [searchParams] = useSearchParams()
    const { currentUser } = useAuth()
    const { conversations, setActiveConversation, activeConversation, setMessages, setMessage, setFiles, setCursor, setHasMore, typingUsers, isLoading } = useMessageContext()
	
	const active = searchParams.get('active');
	
	useEffect(() => {
        if (active && conversations) { 
            // eslint-disable-next-line
            const activeConversation = conversations.find((conversation: any) => conversation.id == atob(unescape(encodeURIComponent(active))));
            if (activeConversation) 
                setActiveConversation(activeConversation)
        }
    // eslint-disable-next-line
    }, [active, conversations]);

    const handleActiveConversation = (conversation: any) => {
        if (activeConversation?.id !== conversation?.id) {
            setMessages([]);
            setMessage('');
            setFiles([]);
            setCursor(0);
            setHasMore(false)
        }
        setActiveConversation(conversation)
    }
	
    return (
        <>
            <div id="conversations-list" className={`flex-column flex-lg-row-auto h-100 w-100 w-lg-300px w-xl-400px mb-lg-0 ${!activeConversation ? 'enable' : ''}`}>
                <div className='card card-flush h-100' id='app_chat_sidebar'>
                    <Search />
                    <div className="d-flex align-items-center justify-content-center" data-kt-indicator={isLoading ? 'on' : 'off'}>
                        <span className="indicator-progress mt-3">
                            <span className="spinner-border spinner-border-sm align-middle"></span>
                        </span>
                    </div>

                    <div className='card-body pt-5' id='kt_chat_contacts_body'>
                        <div
                        className='scroll-y me-n5 pe-5 h-lg-auto'
                        data-kt-scroll='true'
                        data-kt-scroll-activate='{default: false, lg: true}'
                        data-kt-scroll-max-height='auto'
                        data-kt-scroll-dependencies='#kt_chat_contacts_header'
                        data-kt-scroll-wrappers='#kt_chat_contacts_body'
                        data-kt-scroll-offset='0px'
                        >
                            {conversations?.length > 0 && conversations.map((conversation: any) => {
                                const { id, users, latest_message, unread_count } = conversation;
                                const conversationsUsersExcludeCurrentUser = users.filter((user: any) => user.id !== currentUser?.id);
                                return (
                                    <div className='d-flex flex-stack py-4 cursor-pointer' key={id} onClick={() => handleActiveConversation(conversation)}>
                                        <div className='d-flex align-items-center'>
                                            <div className="symbol symbol-circle symbol-45px">
                                                <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="avatar" />
                                            </div>
                                            
                                            <div className='ms-5'>
                                                <span className='fs-5 fw-bolder text-gray-900 mb-2'>
                                                    {conversationsUsersExcludeCurrentUser.map((conversationsUsers: any, index: any) => {
                                                            if (conversationsUsers?.first_name != null && conversationsUsers?.first_name != "") {
                                                                return (
                                                                    <span key={index}>{conversationsUsers?.first_name} {conversationsUsers?.last_name}</span>
                                                                )
                                                            } else {
                                                                return (
                                                                    <span key={index}>{conversationsUsers?.uuid}</span>
                                                                )
                                                            }
                                                        }
                                                    )}
                                                </span>
                                                <div className='fw-bold text-gray-400'>
                                                    {Object.values(typingUsers).some((typingResponse: any) => {
                                                            const { conversation_id, is_typing } = typingResponse;
                                                            if (id === conversation_id && is_typing) {
                                                                return true;
                                                            }
                                                            return false;
                                                        }) ? 'typing...' : latest_message?.message || latest_message?.image || ""
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-column align-items-end ms-2'>
                                            <span className='text-muted fw-bold fs-7 mb-1'>{latest_message?.formatted_created_at}</span>
                                            {
                                                unread_count > 0 &&
                                                <span className="badge badge-success rounded-circle">
                                                    <span className='text-light fs-8'>{unread_count}</span>
                                                </span>
                                            }
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export { Sidebar }